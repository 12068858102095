import DemoLessonButton from "../demoLessonButton/demoLessonButton"
import LearnInfoBlock from "../learnInfoBlock/learnInfoBlock"
import "./learn.css"

function Learn(props) {
    return (
        <section className="learn-section section-container">
            <h2 className="section__title">
                Чему научатся дети в KIBERone?
            </h2>
            <div className="learn-section__blocks-wrapper">
                <div className="learn-section__blocks-wrapper--display-flex">
                    <LearnInfoBlock title="Разрабатывать сайты, компьютерные игры и приложения" />
                    <LearnInfoBlock title="Создавать мультфильмы и монтировать видео" />
                </div>
                <div className="learn-section__blocks-wrapper--display-flex">
                    <div className="learn-section__bear-block">
                        <p className="learn-section__bear-block__title">Проектировать 3D-модели</p>
                    </div>
                    <div style={{ width: "100%" }}>
                        <LearnInfoBlock title="Писать чат-боты и работать в графических редакторах" />
                        <LearnInfoBlock title="Программировать искусственный интеллект и виртуальную реальность" />
                    </div>
                </div>
            </div>
            <div className="learn-section__demo-lesson__wrapper">
                <DemoLessonButton onClick={() => props.setShowModal(true)} />
                <p className="learn-section__demo-lesson__text">
                    Попробуйте, первые уроки бесплатны
                </p>
            </div>
        </section>
    )
}


export default Learn