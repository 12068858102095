import AdvantagesBlock from "../advantagesBlock/advantagesBlock"
import "./schoolAdvantages.css"
import useMouseMove from "../../hooks/useMouseMove"
import BgCircle from "../bgCircle/bgCircle"
import BgBlur from "../bgBlur/bgBlur"

function SchoolAdvantages() {
    const { updateCursorPosition, handleHover, handleLeave, isHovered, x, y } = useMouseMove()

    return (
        <section className="school-advantages-section section-container">
            <h2 className="section__title">
                Преимущества Международной КиберШколы для детей в Самарканде
            </h2>
            <div className="school-advantages-section__advantages-blocks__wrapper"
                onMouseMove={updateCursorPosition}
                onMouseEnter={handleHover}
                onMouseLeave={handleLeave}>
                <AdvantagesBlock number="01" text="Бесплатные дополнительные занятия, если ребенок пропустил или не понял материал" />
                <AdvantagesBlock number="02" text="Формат - КИБЕРшкола, а не краткосрочные курсы,что позволяет обеспечить высокий уровень" />
                <AdvantagesBlock number="03" text="Администраторы на занятиях помогают детям качественно усваивать материал" />
                <AdvantagesBlock number="04" text="На каждом занятии тренируем метод слепой печати на клавиатуре" />
                <AdvantagesBlock number="05" text="Занятия ведут практикующие программисты с опытом работы от 3 лет" />
                <AdvantagesBlock number="06" text="Мини-группы до 15 человек, у каждого ребёнка отдельное рабочее место" />
                <AdvantagesBlock number="07" text="Программа адаптирована для детского восприятия и подходит как технарям, так и гуманитариям" />
                <AdvantagesBlock number="08" text="Возможность участия в значимых мероприятиях в сфере компьютерных технологий по всему миру" />
                <BgCircle isHovered={isHovered} x={x} y={y} bgColor="blue" />
                <BgBlur />
            </div>
        </section>
    )
}

export default SchoolAdvantages