import "./demoLessonButton.css"


function DemoLessonButton(props) {
    return (
        <button className="demo-lesson-button" onClick={props.onClick}>
            Начать обучение <i className="demo-lesson-button__icon fa-solid fa-arrow-right"></i>
        </button>
    )
}

export default DemoLessonButton