import "./technologies.css"
import TechnologiesBlock from "../technologiesBlock/technologiesBlock"
import useMouseMove from "../../hooks/useMouseMove"
import BgCircle from "../bgCircle/bgCircle"
import BgBlur from "../bgBlur/bgBlur"

function Technologies() {
    const { updateCursorPosition, handleHover, handleLeave, isHovered, x, y } = useMouseMove()

    return (
        <section className="technologies-section section-container">
            <div className="technologies-section__block-wrapper"
                onMouseMove={updateCursorPosition}
                onMouseEnter={handleHover}
                onMouseLeave={handleLeave}>
                <div className="technologies-section__block">
                    <div className="technologies-section__technologies-wrapper">
                        <div>
                            <TechnologiesBlock title="Python" backgroundColor="#5096FF" />
                            <TechnologiesBlock title="Сети" backgroundColor="#FFB359" />
                        </div>
                        <div>
                            <TechnologiesBlock title="Базы данных" backgroundColor="#FF6F50" />
                            <TechnologiesBlock title="Фреймворки Flask и Django" backgroundColor="#4BD071" />
                        </div>
                        <div>
                            <TechnologiesBlock title="Отладка и тестирование" backgroundColor="#50C0FF" />
                            <TechnologiesBlock title="Docker" backgroundColor="#4B77B9" />
                            <TechnologiesBlock title="Git" backgroundColor="#AF93FF" />
                        </div>
                    </div>
                    <p className="technologies-section__block-text">
                        Это необходимый минимум для современного разработчика
                    </p>
                </div>
                <BgCircle isHovered={isHovered} x={x} y={y} bgColor="blue" />
                <BgBlur />
            </div>
        </section>
    )
}

export default Technologies