const token = '6714886384:AAGy6Y-wV8Qom7CoPk9c1uLJEERChe-TkeI'
const administrators = [751639920, 6437418896]



export function SendMessage(name, tel) {
    const promises = administrators.map((adminId) => {
        return new Promise((resolve, reject) => {
            let message = `Заявка❗️%0A%0A👤 Имя: ${name}%0A📞 Телефон: %2B${tel.replace('+', '')}`;
            let url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${adminId}&text=${message}`;
            let xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve('success');
                    } else {
                        reject('error');
                    }
                }
            };

            xhr.send();
        });
    });

    return Promise.all(promises);
}
