import "./learnInfoBlock.css";
import useMouseMove from "../../hooks/useMouseMove"
import BgCircle from "../bgCircle/bgCircle";
import BgBlur from "../bgBlur/bgBlur"

function LearnInfoBlock(props) {
    const { updateCursorPosition, handleHover, handleLeave, isHovered, x, y } = useMouseMove()

    return (
        <div className="learn-info-block"
            onMouseMove={updateCursorPosition}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}>
            <p className="learn-info-block__title">{props.title}</p>
            <BgCircle isHovered={isHovered} x={x} y={y} bgColor="blue"/>
            <BgBlur />
        </div>
    );
}

export default LearnInfoBlock;
