import React, { useEffect, useState } from "react";
import "./homePage.css"
import Intro from "../../components/intro/intro";
import Learn from "../../components/learn/learn";
import Technologies from "../../components/technologies/technologies";
import SchoolAdvantages from "../../components/schoolAdvantages/schoolAdvantages";
import SchoolModules from "../../components/schoolModules/schoolModules";
import Comments from "../../components/comments/comments";
import DemoLessonBlock from "../../components/demoLessonBlock/demoLessonBlock";
import Modal from "../../components/modal/modal";



function HomePage() {
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [showModal]);
    return (
        <main className="main">
            <Modal showModal={showModal} setShowModal={setShowModal} />
            <Intro setShowModal={setShowModal} />
            <Learn setShowModal={setShowModal} />
            <Technologies />
            <SchoolAdvantages />
            {/* <SchoolModules /> */}
            {/* <Comments /> */}
            <DemoLessonBlock setShowModal={setShowModal} />
        </main>
    )
}


export default HomePage