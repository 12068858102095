import { useState } from "react";


function useMouseMove(params) {
    const [isHovered, setIsHovered] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const updateCursorPosition = (e) => {
        if (isHovered) {
            const rect = e.currentTarget.getBoundingClientRect();
            setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top });
        }
    };

    const handleHover = () => {
        setIsHovered(true);
    };

    const handleLeave = () => {
        setIsHovered(false);
    };

    return {
        updateCursorPosition,
        handleHover,
        handleLeave,
        isHovered,
        x: position.x,
        y: position.y
    }
}


export default useMouseMove