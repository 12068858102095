import React, { useEffect } from 'react';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from "./hoc/layout/layout";
import HomePage from './pages/homePage/homePage';
import GroupsPage from './pages/groupsPage/groupsPage';
import GroupPage from './pages/groupPage/groupPage';




function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location])


  return (

    <Layout>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<HomePage />} />
        {/* <Route exact path='/groups' element={<GroupsPage />} /> */}
        {/* <Route exact path='/group/:groupId' element={<GroupPage />} /> */}
      </Routes>
    </Layout>

  );

}

export default App;
