import "./advantagesBlock.css"


function AdvantagesBlock(props) {
    return (
        <div className="advantages-block">
            <h3 className="advantages-block__number">{props.number}</h3>
            <p className="advantages-block__text">
                {props.text}
            </p>
        </div>
    )
}

export default AdvantagesBlock