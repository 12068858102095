import "./footer.css"
import kiberoneBlackLogo from "../../images/kiberone-black.webp"
import { Link } from "react-router-dom"

function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-content__wrapper">
                    <div>
                        <div className="footer-logo__wrapper">
                            <img className="footer-logo" src={kiberoneBlackLogo} alt="KiberOne Logo" />
                            <p className="footer-logo__text">Школа программирования</p>
                        </div>
                        <ul className="footer-social-net__list">
                            <li className="footer-social-net__item">
                                <Link className="footer-social-net__link" to="https://www.instagram.com/" target="_blank">
                                    <i className="footer-youtube-icon fa-brands fa-youtube"></i>
                                </Link>
                            </li>

                            <li className="footer-social-net__item">
                                <Link className="footer-social-net__link" to="https://www.instagram.com/" target="_blank">
                                    <i className="footer-facebook-icon fa-brands fa-facebook"></i>
                                </Link>
                            </li>
                            <li className="footer-social-net__item">
                                <Link className="footer-social-net__link" to="https://www.instagram.com/" target="_blank">
                                    <i className="footer-instagram-icon fa-brands fa-instagram"></i>
                                </Link>
                            </li>
                            <li className="footer-social-net__item">
                                <Link className="footer-social-net__link" to="https://www.instagram.com/" target="_blank">
                                    <i className="footer-telegram-icon fa-brands fa-telegram"></i>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <nav className="footer-nav">
                        {/* <ul className="footer-nav__list">
                            <li className="footer-nav__item">
                                <Link className="footer-nav__link" to="/">О КиберШколе</Link>
                            </li>
                            <li className="footer-nav__item">
                                <Link className="footer-nav__link" to="/groups">Группы</Link>
                            </li>
                        </ul> */}
                        <ul className="footer-nav__list">
                            <li className="footer-nav__item">
                                <Link className="footer-nav__link" to="tel:+998910351041">+998 (91) 035 10 41</Link>
                            </li>
                            <li className="footer-nav__item">
                                <Link className="footer-nav__link" to="mailto:samarkand@kiber1.com">samarkand@kiber1.com</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="copyright-block">
                    <p className="copyright">© 2024 KIBERone Samarkand — Все права защищены</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer