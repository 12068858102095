import "./bgCircle.css"

function BgCircle(props) {
    const { isHovered, x, y, bgColor } = props
    return (
        <div
            className={isHovered ? "circle circle--active" : "circle"}
            style={{
                left: `${x}px`,
                top: `${y}px`,
                backgroundColor: `var(--${bgColor})`
            }}
        ></div>
    )
}

export default BgCircle