import { motion } from "framer-motion"
import Footer from "../../components/footer/footer"
import Header from "../../components/header/header"


function Layout(props) {
    return (
        <div className="layout">
            {/* <Header /> */}
            {props.children}
            <Footer />
        </div>
    )
}


export default Layout