import Button from "../ui/button/button"
import "./intro.css"


function Intro(props) {
    return (
        <section className="intro-section section-container">
            <h1 className="intro-section__title section__title">
                Создайте успешное будущее своему ребёнку вместе с IT-навыками от KIBERone!
            </h1>
            <div className="intro-section__subtitle-wrapper">
                <p className="intro-section__subtitle">
                    Не упустите шанс помочь ребёнку построить карьеру!
                </p>
                <Button onClick={() => props.setShowModal(true)}>
                    Пробный урок
                </Button>
            </div>
        </section>
    )
}


export default Intro