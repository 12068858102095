import "./demoLessonBlock.css"
import DemoLessonButton from "../demoLessonButton/demoLessonButton"
import demoLessonBlockImage from "../../images/demo-lesson-block-image.png"

function DemoLessonBlock(props) {
    return (
        <div className="demo-lesson-block">
            <img className="demo-lesson-block__image" src={demoLessonBlockImage} alt="demo lesson" />
            <p className="demo-lesson-block__text">
                Подарите ребёнку возможность получить полезные и востребованные навыки, оставьте заявку на БЕСПЛАТНЫЙ ПРОБНЫЙ УРОК в KIBERone!
            </p>
            <DemoLessonButton onClick={() => props.setShowModal(true)} />
        </div>
    )
}

export default DemoLessonBlock