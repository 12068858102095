import "./technologiesBlock.css"

function TechnologiesBlock(props) {
    const handleMouseEnter = e => {
        e.target.style.boxShadow = `0 0 10px 3px ${props.backgroundColor}`
    }
    const handleMouseLeave = e => {
        e.target.style.boxShadow = `0 0 0 0 ${props.backgroundColor}`
    }
    return (
        <div style={{ backgroundColor: `${props.backgroundColor}` }} className="technologies-block"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            {props.title}
        </div>
    )
}

export default TechnologiesBlock